import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Context } from "hook-ui-kit";
import { createPortal } from "react-dom";

import { sessionKit } from "./header";
import { useLocation } from "react-router-dom";
const Footer = ({ data }: any) => {
  if(useLocation().pathname === "/" || useLocation().pathname === "/contracts") return null
  const { pushTransaction, network }: any = useContext(Context);

  const file_name = data.name;
  const [consoleIsVisible, setConsoleIsVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buildCode, setBuildCode] = useState(data.deployCode);
  const [wallet,setWallet] = useState<any>(null)
  useEffect(() => {
    setBuildCode(data.deployCode);
  }, [data.deployCode]);
  const fetch = async (deploy: boolean) => {
    setConsoleIsVisible(true);
    setLoader(true);
    document.getElementById("#response")!.innerHTML = ``;
    document.getElementById("#error")!.innerHTML = ``;
    try {
      const _data = await axios.post("https://api.hook.tools/browserbuild", {
        data: data.files,
        buildCode,
        wallet,
        file_name_req: file_name,
        deploy: deploy,
        apiURL:
          network === "testnet"
            ? "https://waxtestnet.greymass.com"
            : "https://api.waxsweden.org",
        network,
      });
      document.getElementById("#response")!.innerHTML = _data.data.resp;
      setLoader(false);
      console.log(_data.data)
      return _data.data.id;
    } catch (e: any) {
      document.getElementById("#error")!.innerHTML =
        e.response.data.Error.replace(/\n/g, ` \n\n `);
        setLoader(false);

    }
  };
  useEffect(() => {
    sessionKit.restore().then(async session =>  {
      console.log('session',session)

      if(!session) return
      setWallet(String(session?.actor))
    })
  },[])
  const deploy = async (msigId: any) => {
    const session = await sessionKit.restore().then(async session =>  {
      if(!session) return
      console.log('footer',String(session?.actor),msigId)

      const sa = {
        actions: [{
          account: "eosio.msig",
          name: "approve",
          authorization: [session.permissionLevel],
          data: {
            level: { actor: String(session.actor), permission: "active" },
            proposal_hash: null,
            proposal_name: msigId,
            proposer: "hookdeployer",
          },
        },
        {
          account: "eosio.msig",
          name: "exec",
          authorization: [session.permissionLevel],
          data: {
            executer: String(session.actor),
            proposal_name: msigId,
            proposer: "hookdeployer",
          },
        },]
      }
      console.log(sa)
      const result = await session.transact(sa)
      
  })
  return
    pushTransaction([
      {
        account: "eosio.msig",
        name: "approve",
        data: {
          level: { actor: wallet, permission: "active" },
          proposal_hash: null,
          proposal_name: msigId,
          proposer: "hookdeployer",
        },
      },
      {
        account: "eosio.msig",
        name: "exec",
        data: {
          executer: wallet,
          proposal_name: msigId,
          proposer: "hookdeployer",
        },
      },
    ])
      .then(() => {
        setModalIsVisible(true);
        setModalError(false);
        setModalText("");

        setTimeout(() => setModalIsVisible(false), 5000);
      })
      .catch((e: any) => {
        setModalIsVisible(true);
        setModalError(true);
        setModalText(e.toString());

        setTimeout(() => setModalIsVisible(false), 5000);
      });
  };
  useEffect(() => {
    const ball: any = document.querySelector(".footer");
    ball.addEventListener("dragstart", (e: any) => {
      console.log(e.screenY);
    });
  }, []);
  const [modalIsBisible, setModalIsVisible] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalText, setModalText] = useState("txHash");
  return (
    <div className="footer">
      {modalIsBisible &&
        createPortal(
          <div
            style={!modalError ? {} : { background: "red" }}
            className="toast"
          >
            <p>{modalError ? "Error" : "Success"}</p>
            <p>{modalText}</p>
          </div>,
          document.body
        )}
      <div className="build-panel">
        <input
          onChange={(e) => setBuildCode(e.target.value)}
          value={buildCode}
          type="text"
        />

        <button
          title="Connect your wallet"
          disabled={loader}
          onClick={() => fetch(false)}
        >
          Build
        </button>
        <button
          onClick={() => fetch(true).then((msigId) => deploy(msigId))}
          disabled={!wallet || loader}
        >
          Deploy
        </button>
        <button
          className="console-open-btn"
          onClick={() => {
            setConsoleIsVisible(!consoleIsVisible);
          }}
        >
          ...
        </button>
      </div>
      <div
        style={consoleIsVisible ? {} : { display: "none" }}
        className="footer-console"
      >
        <p style={{ display: "flex", alignItems: "center" }}>
          {buildCode}
          {loader ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ background: "none", shapeRendering: "auto" }}
                height="50px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="rotate(0 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.9166666666666666s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(30 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.8333333333333334s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(60 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.75s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(90 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.6666666666666666s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(120 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.5833333333333334s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(150 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.5s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(180 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.4166666666666667s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(210 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.3333333333333333s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(240 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.25s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(270 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.16666666666666666s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(300 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="-0.08333333333333333s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
                <g transform="rotate(330 50 50)">
                  <rect
                    x="47"
                    y="24"
                    rx="3"
                    ry="6"
                    width="6"
                    height="12"
                    fill="#a8a188"
                  >
                    <animate
                      attributeName="opacity"
                      values="1;0"
                      keyTimes="0;1"
                      dur="1s"
                      begin="0s"
                      repeatCount="indefinite"
                    />
                  </rect>
                </g>
              </svg>
            </>
          ) : (
            ""
          )}
        </p>
        <p id={"#response"}></p>
        <p className="error" id={"#error"}></p>
      </div>
    </div>
  );
};
export default Footer;
