import React from "react"
import { Files } from "../interface"
const Folders = ({folder
    ,idChandler,id,changeName, inputChandler , setChoose,setHide}:{changeName:Function,inputChandler:Function,setHide:Function,folder:Files[], idChandler:Function,id:number,setChoose:Function}) => {
    
    const isCPP = (name:string) => {
      let _name = name.split('.');
      if (_name[_name.length - 1] === 'cpp' || _name[_name.length - 1] === 'hpp') return true
    }
      return( 
      <>
        {folder.map((post:Files) => 
        <>
          {
            post.input ?
              <input
                style={{width: '100%', color: '#FFF', background: post.choose ? '#80808042' : 'gray', padding: '5px'}}
                onChange={(e) => changeName(post.id,e.target.value)} id={`#name`} value={post.name} />
            :
            <button
            onClick={(e) => {
              if (e.detail === 2) {
                inputChandler(post.id)
              } else {
                post.type === 'folder' ? idChandler(post.id) : setChoose(post.id)
              }

              }
            }
            style={
              post.id === id || post.choose ? 
              {display:'flex', alignItems: 'center', width: '100%', color: '#FFF', background: post.choose ? '#80808042' : 'gray', padding: '5px'}
              : {display:'flex', alignItems: 'center', width: '100%',padding: '5px',color: '#FFF'}
              }>
            {post.type === 'folder' ? 
            <button style={post.hide ? {} : {rotate: '90deg'}} onClick={() => setHide(post.id)}>
            <svg className="triangle" viewBox="0 0 100 100">
              <polygon points="50 15, 100 100, 0 100"/>
            </svg>
          </button>
          :
          ""}
            <img width={20} style={post.type === 'folder' ? {display: 'none'} : {}} src={ isCPP(post.name) ? require('../img/c++.png') : require('../img/file.png')} alt="" />{post.name} 
          </button>
          }
            <div style={post.hide ? {marginLeft: '10px',display: 'none'} : {marginLeft: '10px'}}>
              <Folders changeName={changeName} inputChandler={inputChandler} setHide={setHide} id={id} folder={post.files} idChandler={idChandler} setChoose={setChoose}/>
            </div>
        </>
        )}
      </>
    )
  }

export default Folders