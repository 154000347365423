import React,{useCallback, useContext, useEffect, useState} from 'react';
import './App.scss';
import CodeEditor from './components/codeEditor';
import { Files, Files_UP } from './interface';
import Folders from './components/folders';
import Header from './components/header';
import Footer from './components/footer';
import NavBar from './components/navbar';
import { Context } from 'hook-ui-kit';
import { useLocation } from 'react-router-dom';

function App() {
  const path = useLocation().pathname
  console.log("path:",path)
  const [id,setId] = useState(0)
  const [isInput, setIsInput] = useState(false)
  const idChandler = (id:number) => {
    setId(id)
  }
  const [files, setFiles] = useState<Files_UP>({
    name: "",
    id: 123,
    deployCode: "",
    files: []})
    const url = useLocation().pathname
    useEffect(() => {
      if(url === "" || url === "/contracts" ) {
        setFiles({
          name: "",
          id: 123,
          deployCode: "",
          files: []})
      }
    
    },[url])
    const fileChandler = (data:Files_UP) => {
    setFiles(data)
  }
  const delFolder = useCallback((id:number,files:any) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))
    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          delete arr[i];
          return
        }
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    function delEmpty(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i]) {
          arr.splice(i,1)
          return
        }
          let __files = arr[i].files;
          delEmpty(__files);
      }
    }
    delEmpty(_files.files)
    setFiles(_files)
  },[])
  const addFolder = (id:number) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))
    if (id === 0) {
      _files.files.push({name: "name",input: true,type: 'folder', files: [] ,id: Math.random()*1000000})
      setFiles(_files)
      setTimeout(() => {
        let input = document.getElementById(`#name`) as HTMLInputElement || null
        if (input) {
          input.focus()
        } else {
          console.log(input)
        }
      },100)
      setIsInput(true)
      return
    }
    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].hide = false;
          arr[i].files.push({name: "new_folder",input: true,type: 'folder', files: [] ,id: Math.random()*1000000})
          return
        }
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files)
    setIsInput(true)
    setTimeout(() => {
      let input = document.getElementById(`#name`) as HTMLInputElement || null
      if (input) {
        input.focus()
      } else {
        console.log(input)
      }
    },100)
  }
  const addFile = (id:number) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))
    let _id = Math.random()*1000000
    if (id === 0) {
      _files.files.push({
        name: "new_file.cpp",
        type: 'file',
        files: [] ,
        id: _id,
        data: '',
        open: true,
        choose: true,
        input: true})
      setFiles(_files)
      setTimeout(() => {
        let input = document.getElementById(`#name`) as HTMLInputElement || null
        if (input) {
          input.focus()
        } else {
          console.log(input)
        }
      },100)
      setIsInput(true)
      return
    }
    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].hide = false;
          arr[i].files.push({
            name: "new_file.cpp",
            type: 'file',
            files: [] ,
            id: _id,
            data: '',
            open: true,
            choose: true,
            input: true})
        } else if ( _id !== arr[i].id ){
          arr[i].choose = false
        }
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files)
    setIsInput(true)
    setTimeout(() => {
      let input = document.getElementById(`#name`) as HTMLInputElement || null
      if (input) {
        input.focus()
      } else {
        console.log(input)
      }
    },100)
  }
  const getOpen_ = (files:Files[]) => {
    let _open:any[] = []
    function getOpen(_files:Files[],_open:any[]) {
      for (let i = 0; i < _files.length; i++){
        if (_files[i].open) {
          _open.push({..._files[i]})
        }
        let __files = _files[i].files
        getOpen(__files,_open)
      }
    }
    getOpen(files,_open)
    return(_open)
  }
  const setChoose = (id:number) =>{
    let _files:Files_UP = JSON.parse(JSON.stringify(files))

    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].open = true;
          arr[i].choose = true
        } else if ( id !== arr[i].id ){
          arr[i].choose = false
        }
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files)
  }
  const setChooseData =(data:string) => {
    let ret;
    let _files:Files_UP = JSON.parse(JSON.stringify(files))
    function getOpen(_files:Files[],) {
      for (let i = 0; i < _files.length; i++){
        if (_files[i].choose) {
          ret = _files[i]
          _files[i].data = data;
          return
        }
        let __files = _files[i].files
        getOpen(__files)
      }
    }
    getOpen(_files.files)
    setFiles(_files)
    let localContracts:Files_UP[] = JSON.parse(localStorage.getItem('contracts') || '');
    if (_files.id < 5 ) {
      return ret
    }
    localContracts.find((post:Files_UP) => {
      if(post.id === _files.id) {
        post.deployCode = _files.deployCode
        post.files = _files.files
        post.id = _files.id
        post.name = _files.name
      }
    })

    localStorage.setItem('contracts', JSON.stringify(localContracts))

    return ret
  }
  const getChoose = (): Files | undefined => {
    let ret;
    function getOpen(_files:Files[],) {
      for (let i = 0; i < _files.length; i++){
        if (_files[i].choose) {
          ret = _files[i]
          return
        }
        let __files = _files[i].files
        getOpen(__files)
      }
    }
    getOpen(files!.files)

    return ret
  }

  const inputChandler = (id:number) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))

    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].input = true
          return
        } 
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files);
    setTimeout(() => {
      let input = document.getElementById(`#name`) as HTMLInputElement || null
      if (input) {
        input.focus()
      } else {
        console.log(input)
      }
    },100)
    setIsInput(true)
  }
  const inputBlock = () => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))
    function getOpen(_files:Files[],) {
      for (let i = 0; i < _files.length; i++){
          _files[i].input = false;
        let __files = _files[i].files
        getOpen(__files)
      }
    }
    getOpen(_files.files)
    setFiles(_files)
    setIsInput(false)
  }
  const changeName = (id:number,name:string) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))

    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].name = name;
          return
        } 
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files)
  }
  const setHide = (id:number) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))

    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].hide = !arr[i].hide
          return
        } 
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files)
  }
  const closeFile = (id:number) => {
    let _files:Files_UP = JSON.parse(JSON.stringify(files))

    function checkFolder(arr:Files[]){
      for (let i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          arr[i].open = false;
          arr[i].choose = false;
          return
        } 
          let __files = arr[i].files;
          checkFolder(__files);
      }
    }
    checkFolder(_files.files)
    setFiles(_files)
  }
  useEffect(() => {
    function getDel(event:any) {
      if (event.key === "Delete") {
        delFolder(id,files);
      } else if (event.key === "Enter" || event.keyCode === 13) {
        inputBlock()
      }
    }
    document.addEventListener("keydown", getDel);
    return () => document.removeEventListener("keydown",getDel);
  },[id,files])
  return (
    <div className='App'>
      <Header files={files} fileChandler={fileChandler} />
      <NavBar />
      <div style={{display: 'flex', width: '100%', height: 30}}>
        <div style={path === "/" || path === '/contracts' ? {display: 'none'} :{}} className='create-files-div'>
            <button onClick={() => addFolder(id)}>
              <svg className='folder-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM11 12H8v2h3v3h2v-3h3v-2h-3V9h-2v3z"/> </g> </svg>
            </button>
            <button onClick={() => addFile(id)}>
            <svg className='folder-svg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4zm-2.5 6.5A.5.5 0 0 1 6 10h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" fill="white"></path> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" fill="white"></path> </svg></button>
        </div>
        <div style={{display: 'flex'}}>
          {getOpen_(files!.files).map((post:any) => 
            <div style={!post.choose ? {background: 'rgb(43, 42, 42)'} : {}} className='files-tabs'>
              <button onClick={() => setChoose(post.id)}>{post.name}</button>
              <button onClick={() => closeFile(post.id)}>x</button>
            </div>
          )}
        </div>
      </div>
      <div className='main-div'>
        <div style={id === 0 ? path === "/" || path ==="/contracts" ? {display: 'none'} : {border: '1px rgb(16, 110, 190) solid'} : path === "/" || path === '/contracts' ? {display: 'none'} :{}}  className='folder-panel'>
          <button style={{minWidth: '300px', height:'100%', position:'absolute',top:0, zIndex: 0, background: 'none'}} onClick={() => setId(0)}></button>
          <div style={{position: 'absolute', zIndex: 1,width: '100%',}}>
            <Folders changeName={changeName} inputChandler={inputChandler} setHide={setHide} setChoose={setChoose} id={id} folder={files!.files} idChandler={idChandler} />
          </div>
        </div>
        <CodeEditor fileChandler={fileChandler} setChooseData={setChooseData} getChoose={getChoose} />
      </div>
      <Footer data={files}/>
      {isInput ?
        <button onClick={inputBlock} className='close-input'></button> : ''}
      </div>
  );
}

export default App;
