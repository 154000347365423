import React from "react"
import { useNavigate } from "react-router-dom"

export const WelcomePage = () => {
    const navigate = useNavigate()
    return       <div className='choose-contract'>
    <div onClick={() => navigate("/contracts")}>
      <h3>Choose contracts</h3>
      <p>On our platform, it will be possible to deploy basic contracts in one click. You will not need any programming knowledge.</p>
    </div>
    <div onClick={() => window.location.href = 'https://docs.hook.tools/'}>
      <h3>Learn</h3>
      <p>Discover how to build and launch a web3 application.</p>
    </div>
    <div onClick={() => window.location.href = 'https://developer.wax.io/'}>
      <h3>WAX Developer Portal</h3>
      <p>The Gateway for Building on the WAX Blockchain</p>
    </div>
    <div onClick={() => window.location.href = 'https://wharfkit.com/'}>
      <h3>WharfKIT</h3>
      <p>Modular SDK for Antelope Web Apps.</p>
    </div>
    <div>
      <h3 onClick={() => window.location.href = 'https://docs.hook.tools/support/faq'}>FAQ</h3>
      <p style={{fontWeight: 600}}>What is Hook Tools? </p>
    </div>

  </div>
}