import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HookProvider } from 'hook-ui-kit';
import { HashRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HashRouter>
        <HookProvider
      network='testnet'
      colorMode='dark'
      api='https://testnet.waxsweden.org'>
      <div className='lock-screen'>
        <p>This website doesn't work on mobile.</p>
        <p>Please try on your desktop.</p>
      </div>
      <App />
    </HookProvider>
  </HashRouter>
);
