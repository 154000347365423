import React,{useState,useEffect} from 'react';
import { cpp,cppLanguage } from '@codemirror/lang-cpp';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './style.scss'
import { Files_UP,Files } from '../interface';
import { WelcomePage } from './welcome-page';
const CodeEditor = ({getChoose,setChooseData,fileChandler}:any) => {
    const navigate = useNavigate()
    const path = useLocation().pathname

  const [choose,setChoose] = useState(false);
  const [newContractName, setNewContractName] = useState('hooktools')
  const [contracts, setContracts] = useState<Files_UP[]>([
    {
        name: 'eosio.token',
        id: 0,
        deployCode: `eosio-cpp -abigen -I include -R ricardian -contract eosio.token -o eosio.token.wasm src/eosio.token.cpp`,
        files: [{
            name: 'src',
            type: 'folder',
            hide: false,
            files: [{
            name: 'eosio.token.cpp',
            type: 'file',
            files: [] ,
            id: Math.random()*1000000,
            data: `#include <eosio.token.hpp>

namespace eosio {

void token::create( const name&   issuer,
                    const asset&  maximum_supply )
{
    require_auth( get_self() );

    auto sym = maximum_supply.symbol;
    check( sym.is_valid(), "invalid symbol name" );
    check( maximum_supply.is_valid(), "invalid supply");
    check( maximum_supply.amount > 0, "max-supply must be positive");

    stats statstable( get_self(), sym.code().raw() );
    auto existing = statstable.find( sym.code().raw() );
    check( existing == statstable.end(), "token with symbol already exists" );

    statstable.emplace( get_self(), [&]( auto& s ) {
        s.supply.symbol = maximum_supply.symbol;
        s.max_supply    = maximum_supply;
        s.issuer        = issuer;
    });
}


void token::issue( const name& to, const asset& quantity, const string& memo )
{
    auto sym = quantity.symbol;
    check( sym.is_valid(), "invalid symbol name" );
    check( memo.size() <= 256, "memo has more than 256 bytes" );

    stats statstable( get_self(), sym.code().raw() );
    auto existing = statstable.find( sym.code().raw() );
    check( existing != statstable.end(), "token with symbol does not exist, create token before issue" );
    const auto& st = *existing;
    check( to == st.issuer, "tokens can only be issued to issuer account" );

    require_auth( st.issuer );
    check( quantity.is_valid(), "invalid quantity" );
    check( quantity.amount > 0, "must issue positive quantity" );

    check( quantity.symbol == st.supply.symbol, "symbol precision mismatch" );
    check( quantity.amount <= st.max_supply.amount - st.supply.amount, "quantity exceeds available supply");

    statstable.modify( st, same_payer, [&]( auto& s ) {
        s.supply += quantity;
    });

    add_balance( st.issuer, quantity, st.issuer );
}

void token::retire( const asset& quantity, const string& memo )
{
    auto sym = quantity.symbol;
    check( sym.is_valid(), "invalid symbol name" );
    check( memo.size() <= 256, "memo has more than 256 bytes" );

    stats statstable( get_self(), sym.code().raw() );
    auto existing = statstable.find( sym.code().raw() );
    check( existing != statstable.end(), "token with symbol does not exist" );
    const auto& st = *existing;

    require_auth( st.issuer );
    check( quantity.is_valid(), "invalid quantity" );
    check( quantity.amount > 0, "must retire positive quantity" );

    check( quantity.symbol == st.supply.symbol, "symbol precision mismatch" );

    statstable.modify( st, same_payer, [&]( auto& s ) {
        s.supply -= quantity;
    });

    sub_balance( st.issuer, quantity );
}

void token::transfer( const name&    from,
                    const name&    to,
                    const asset&   quantity,
                    const string&  memo )
{
    check( from != to, "cannot transfer to self" );
    require_auth( from );
    check( is_account( to ), "to account does not exist");
    auto sym = quantity.symbol.code();
    stats statstable( get_self(), sym.raw() );
    const auto& st = statstable.get( sym.raw() );

    require_recipient( from );
    require_recipient( to );

    check( quantity.is_valid(), "invalid quantity" );
    check( quantity.amount > 0, "must transfer positive quantity" );
    check( quantity.symbol == st.supply.symbol, "symbol precision mismatch" );
    check( memo.size() <= 256, "memo has more than 256 bytes" );

    auto payer = has_auth( to ) ? to : from;

    sub_balance( from, quantity );
    add_balance( to, quantity, payer );
}

void token::sub_balance( const name& owner, const asset& value ) {
    accounts from_acnts( get_self(), owner.value );

    const auto& from = from_acnts.get( value.symbol.code().raw(), "no balance object found" );
    check( from.balance.amount >= value.amount, "overdrawn balance" );

    from_acnts.modify( from, owner, [&]( auto& a ) {
        a.balance -= value;
    });
}

void token::add_balance( const name& owner, const asset& value, const name& ram_payer )
{
    accounts to_acnts( get_self(), owner.value );
    auto to = to_acnts.find( value.symbol.code().raw() );
    if( to == to_acnts.end() ) {
    to_acnts.emplace( ram_payer, [&]( auto& a ){
        a.balance = value;
    });
    } else {
    to_acnts.modify( to, same_payer, [&]( auto& a ) {
        a.balance += value;
    });
    }
}

void token::open( const name& owner, const symbol& symbol, const name& ram_payer )
{
    require_auth( ram_payer );

    check( is_account( owner ), "owner account does not exist" );

    auto sym_code_raw = symbol.code().raw();
    stats statstable( get_self(), sym_code_raw );
    const auto& st = statstable.get( sym_code_raw, "symbol does not exist" );
    check( st.supply.symbol == symbol, "symbol precision mismatch" );

    accounts acnts( get_self(), owner.value );
    auto it = acnts.find( sym_code_raw );
    if( it == acnts.end() ) {
    acnts.emplace( ram_payer, [&]( auto& a ){
        a.balance = asset{0, symbol};
    });
    }
}

void token::close( const name& owner, const symbol& symbol )
{
    require_auth( owner );
    accounts acnts( get_self(), owner.value );
    auto it = acnts.find( symbol.code().raw() );
    check( it != acnts.end(), "Balance row already deleted or never existed. Action won't have any effect." );
    check( it->balance.amount == 0, "Cannot close because the balance is not zero." );
    acnts.erase( it );
}

} /// namespace eosio`,
            open: true,
            choose: false}],
            id: Math.random()*1000000
        },{
            name: 'ricardian',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
        },{
            name: 'include',
            type: 'folder',
            files: [{
                name: 'eosio.token.hpp',
                type: 'file',
                files: [] ,
                id: Math.random()*1000000,
                data: `#pragma once

#include <eosio/asset.hpp>
#include <eosio/eosio.hpp>

#include <string>

namespace eosiosystem {
    class system_contract;
}

namespace eosio {

    using std::string;

    /**
    * The 'eosio.token' sample system contract defines the structures and actions that allow users to create, issue, and manage tokens for EOSIO based blockchains. It demonstrates one way to implement a smart contract which allows for creation and management of tokens. It is possible for one to create a similar contract which suits different needs. However, it is recommended that if one only needs a token with the below listed actions, that one uses the 'eosio.token' contract instead of developing their own.
    * 
    * The 'eosio.token' contract class also implements two useful public static methods: 'get_supply' and 'get_balance'. The first allows one to check the total supply of a specified token, created by an account and the second allows one to check the balance of a token for a specified account (the token creator account has to be specified as well).
    * 
    * The 'eosio.token' contract manages the set of tokens, accounts and their corresponding balances, by using two internal multi-index structures: the 'accounts' and 'stats'. The 'accounts' multi-index table holds, for each row, instances of 'account' object and the 'account' object holds information about the balance of one token. The 'accounts' table is scoped to an eosio account, and it keeps the rows indexed based on the token's symbol.  This means that when one queries the 'accounts' multi-index table for an account name the result is all the tokens that account holds at the moment.
    * 
    * Similarly, the 'stats' multi-index table, holds instances of 'currency_stats' objects for each row, which contains information about current supply, maximum supply, and the creator account for a symbol token. The 'stats' table is scoped to the token symbol.  Therefore, when one queries the 'stats' table for a token symbol the result is one single entry/row corresponding to the queried symbol token if it was previously created, or nothing, otherwise.
    */
    class [[eosio::contract("eosio.token")]] token : public contract {
        public:
            using contract::contract;

            /**
             * Allows 'issuer' account to create a token in supply of 'maximum_supply'. If validation is successful a new entry in statstable for token symbol scope gets created.
             *
             * @param issuer - the account that creates the token,
             * @param maximum_supply - the maximum supply set for the token created.
             *
             * @pre Token symbol has to be valid,
             * @pre Token symbol must not be already created,
             * @pre maximum_supply has to be smaller than the maximum supply allowed by the system: 1^62 - 1.
             * @pre Maximum supply must be positive;
             */
            [[eosio::action]]
            void create( const name&   issuer,
                        const asset&  maximum_supply);
            /**
             *  This action issues to 'to' account a 'quantity' of tokens.
             *
             * @param to - the account to issue tokens to, it must be the same as the issuer,
             * @param quntity - the amount of tokens to be issued,
             * @memo - the memo string that accompanies the token issue transaction.
             */
            [[eosio::action]]
            void issue( const name& to, const asset& quantity, const string& memo );

            /**
             * The opposite for create action, if all validations succeed,
             * it debits the statstable.supply amount.
             *
             * @param quantity - the quantity of tokens to retire,
             * @param memo - the memo string to accompany the transaction.
             */
            [[eosio::action]]
            void retire( const asset& quantity, const string& memo );

            /**
             * Allows 'from' account to transfer to 'to' account the 'quantity' tokens.
             * One account is debited and the other is credited with quantity tokens.
             *
             * @param from - the account to transfer from,
             * @param to - the account to be transferred to,
             * @param quantity - the quantity of tokens to be transferred,
             * @param memo - the memo string to accompany the transaction.
             */
            [[eosio::action]]
            void transfer( const name&    from,
                        const name&    to,
                        const asset&   quantity,
                        const string&  memo );
            /**
             * Allows 'ram_payer' to create an account 'owner' with zero balance for
             * token 'symbol' at the expense of 'ram_payer'.
             *
             * @param owner - the account to be created,
             * @param symbol - the token to be payed with by 'ram_payer',
             * @param ram_payer - the account that supports the cost of this action.
             *
             * More information can be read [here](https://github.com/EOSIO/eosio.contracts/issues/62)
             * and [here](https://github.com/EOSIO/eosio.contracts/issues/61).
             */
            [[eosio::action]]
            void open( const name& owner, const symbol& symbol, const name& ram_payer );

            /**
             * This action is the opposite for open, it closes the account 'owner'
             * for token 'symbol'.
             *
             * @param owner - the owner account to execute the close action for,
             * @param symbol - the symbol of the token to execute the close action for.
             *
             * @pre The pair of owner plus symbol has to exist otherwise no action is executed,
             * @pre If the pair of owner plus symbol exists, the balance has to be zero.
             */
            [[eosio::action]]
            void close( const name& owner, const symbol& symbol );

            static asset get_supply( const name& token_contract_account, const symbol_code& sym_code )
            {
            stats statstable( token_contract_account, sym_code.raw() );
            const auto& st = statstable.get( sym_code.raw() );
            return st.supply;
            }

            static asset get_balance( const name& token_contract_account, const name& owner, const symbol_code& sym_code )
            {
            accounts accountstable( token_contract_account, owner.value );
            const auto& ac = accountstable.get( sym_code.raw() );
            return ac.balance;
            }

            using create_action = eosio::action_wrapper<"create"_n, &token::create>;
            using issue_action = eosio::action_wrapper<"issue"_n, &token::issue>;
            using retire_action = eosio::action_wrapper<"retire"_n, &token::retire>;
            using transfer_action = eosio::action_wrapper<"transfer"_n, &token::transfer>;
            using open_action = eosio::action_wrapper<"open"_n, &token::open>;
            using close_action = eosio::action_wrapper<"close"_n, &token::close>;
        private:
            struct [[eosio::table]] account {
            asset    balance;

            uint64_t primary_key()const { return balance.symbol.code().raw(); }
            };

            struct [[eosio::table]] currency_stats {
            asset    supply;
            asset    max_supply;
            name     issuer;

            uint64_t primary_key()const { return supply.symbol.code().raw(); }
            };

            typedef eosio::multi_index< "accounts"_n, account > accounts;
            typedef eosio::multi_index< "stat"_n, currency_stats > stats;

            void sub_balance( const name& owner, const asset& value );
            void add_balance( const name& owner, const asset& value, const name& ram_payer );
    };

}`,
                open: true,
                choose: true}],
            hide: false,
            id: Math.random()*1000000
        },
        ]
    },
    {
        name: 'DAO',
        id: 1,
        deployCode: `eosio-cpp -abigen -I include -R ricardian -contract dao -o dao.wasm src/dao.cpp`,    
        files: [{
            name: 'src',
            type: 'folder',
            hide: false,
            files: [{
            name: 'dao.cpp',
            type: 'file',
            files: [] ,
            id: Math.random()*1000000,
            data: `#include <eosio/eosio.hpp>
#include <eosio/multi_index.hpp>
#include <eosio/action.hpp>
#include <eosio/transaction.hpp>
#include <eosio/asset.hpp>
#include <eosio/crypto.hpp>
#include <eosio/time.hpp>


using namespace eosio;
using std::string;
using std::vector;

name tokencontract = name("eosio.token");


CONTRACT dao : public eosio::contract {
public:
dao( name receiver, name code, datastream<const char*> ds ):
    contract(receiver, code, ds),
    _vote(receiver, receiver.value),
    _users(receiver, receiver.value)
    {}


    [[eosio::on_notify("eosio.token::transfer")]]
    void transfertoken(name from,name to, asset quantity, string memo){
        auto itr_users = _users.find(from.value);
        if ( itr_users == _users.end() ) { return; } ;
        for  ( int i = 0; i < itr_users->votes.size() ; i++ ) {
            
            _users.modify(itr_users, get_self(), [&](auto& row) {
                row.votes[i]._votes -= quantity.amount;
            });
        } 
    }




    ACTION createvote(name username, string header, string description,vector<string> votes){
        require_auth(username);

        vector <votes_struct> votes_struct = {};

        for(int i = 0; i < votes.size(); i++ ) {
            votes_struct.push_back({
                ._name = votes[i],
                ._votes = 0
            });
        }
        auto itr_vote = _vote.end();
        uint64_t num = itr_vote->num ? itr_vote->num + 1 : 0;
        _vote.emplace(username, [&](auto& row) {
            row.num = num;
            row.header = header;
            row.description = description;
            row.creator = username;
            row.votes = votes_struct;
        });
    };

    ACTION editvote(name username, uint64_t id, string header, string description,vector<string> votes){
        require_auth(username);
        auto itr_vote = _vote.find(id);
        check (itr_vote->creator == username,"Identification error");

        vector <votes_struct> votes_struct = {};

        for(int i = 0; i < votes.size(); i++ ) {
            votes_struct.push_back({
                ._name = votes[i],
                ._votes = 0
            });
        }

        _vote.modify(itr_vote, username, [&](auto& row) {
            row.header = header;
            row.description = description;
            row.votes = votes_struct;
        });
    };

    ACTION aprovevote(uint64_t num,uint64_t _end) {
        require_auth(get_self());
        auto itr_vote = _vote.find(num);
        _vote.modify(itr_vote, get_self(),[&](auto& row) {
            row.aprove = true;
            row._end = _end;
        });
    };

    ACTION delvote(uint64_t num) {
        require_auth(get_self());
        auto itr_vote = _vote.find(num);
        _vote.erase(itr_vote);
    };

    ACTION setvots(name username, uint64_t vote_id, uint64_t votes, string vote_name) {
        require_auth(username);
        
        //check token balance
        balance_u balance = get_assets(username);
        auto itr_tokens = balance.begin();
        check ( votes <= itr_tokens->balance.amount,"token amount error");

        auto itr_user = _users.find(username.value);
        if ( itr_user == _users.end() ) {
            vector<users_struct> users_struct;
            users_struct.push_back({
                ._name = vote_name,
                ._votes = votes,
                ._vote_id = vote_id
            });
            
            _users.emplace(username, [&](auto& row) {
                row.username = username;
                row.votes = users_struct;
            });
        } else {
            bool cheched = false;
            for ( int i = 0; i < itr_user->votes.size() ; i++ ) {
                if ( vote_id == itr_user->votes[i]._vote_id && vote_name == itr_user->votes[i]._name ) {
                    check ( (votes + itr_user->votes[i]._votes) <= itr_tokens->balance.amount,"token amount error" );
                    _users.modify(itr_user, username, [&](auto& row) {
                        row.votes[i]._votes += votes;
                    });
                    cheched = true;
                    break;
                }
            }
            if ( cheched == false ) {
                _users.modify(itr_user, username, [&](auto& row) {
                    row.votes.push_back({
                        ._name = vote_name,
                        ._votes = votes,
                        ._vote_id = vote_id
                    });
                });
            }
        }



        auto itr_vote = _vote.require_find(vote_id,"Identification error");
        bool vote_true;
        for ( int i = 0; i< itr_vote->votes.size();i++ ) {
            if  ( vote_name == itr_vote->votes[i]._name ) {
                
                _vote.modify(itr_vote,username, [&](auto& row) {
                    row.votes[i]._votes += votes;
                });

                vote_true = true;
                break;
            }
        }
        check ( vote_true == true,"vote_name error");
        
        require_recipient(username);
    };

private:

    struct votes_struct
    {   
        string _name;
        uint64_t _votes;
    };

    struct users_struct
    {   
        uint64_t _vote_id;
        string _name;
        uint64_t _votes;
    };


    struct  account_table {
        asset    balance;

        uint64_t primary_key()const { return balance.symbol.code().raw(); }
    };

    typedef multi_index <name("accounts"), account_table> balance_u;

    TABLE user {
        name username;
        vector<users_struct> votes;

        auto primary_key() const { return username.value; }
    };

    typedef multi_index<name("users"), user> users;
    users _users;


    TABLE vote {
        uint64_t num;
        name creator;
        string header;
        string description;
        vector<votes_struct> votes;
        bool aprove;
        uint64_t _end;

        auto primary_key() const { return num; }
    };
    typedef multi_index<name("votes"), vote> votes;
    votes _vote;

    balance_u get_assets(name username) {
        return balance_u(tokencontract, username.value);
    }
};`,
            open: true,
            choose: true}],
            id: Math.random()*1000000
        },{
            name: 'ricardian',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
        },{
            name: 'include',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
        },
        ]
    },
    {
        name: 'TOKENstaking',
        id: 3,
        deployCode: `eosio-cpp -abigen -I include -R ricardian -contract tokenstaking -o tokenstaking.wasm src/tokenstaking.cpp`,    
        files: [{
            name: 'src',
            type: 'folder',
            hide: false,
            files: [{
              name: 'tokenstaking.cpp',
              type: 'file',
              files: [] ,
              id: Math.random()*1000000,
              data: `#include <eosio/eosio.hpp>
#include <eosio/multi_index.hpp>
#include <eosio/action.hpp>
#include <eosio/transaction.hpp>
#include <eosio/asset.hpp>
#include <eosio/crypto.hpp>
#include <eosio/time.hpp>


using namespace eosio;
using std::string;
using std::vector;

name tokencontract = name("eosio.token");


CONTRACT tokenstaking : public eosio::contract {
public:
tokenstaking( name receiver, name code, datastream<const char*> ds ):
contract(receiver, code, ds),
_users(receiver, receiver.value),
_config(receiver, receiver.value)
{}


[[eosio::on_notify("eosio.token::transfer")]]
void transfertoken(name from,name to, asset quantity, string memo){
    auto itr_users = _users.find(from.value);

    uint64_t time = stoi(memo);
    uint64_t current_time = current_time_point().sec_since_epoch();
    auto itr_apy = _config.find(get_self().value);
    double apy = 0;

    for ( int i = 0; i < itr_apy->APY.size(); i++ ) {
        if ( itr_apy->APY[i].locktime == time ) {
            apy = itr_apy->APY[i].apy;
        }
    }
    check ( apy != 0,"Locktime error");
    if ( itr_users == _users.end() ) {

        vector<stake_tokens> stake_tokens;
        stake_tokens.push_back({
            .locktime = current_time + time,
            .starttime = current_time,
            .token = quantity,
            .apy = apy
        });

        _users.emplace(get_self(), [&](auto& row) {
            row.username = from;
            row.stake = stake_tokens;
        });
    } else {
        _users.modify(itr_users, get_self(), [&](auto& row) {
            row.stake.push_back({
                .locktime = current_time + time,
                .starttime = current_time,
                .token = quantity,
                .apy = apy
            });
        });
    }
}

ACTION claim ( name username, uint64_t id ) {
    require_auth(username);

    auto itr_users = _users.require_find(username.value,"Error with table find");
    uint64_t current_time = current_time_point().sec_since_epoch();
    check ( current_time < itr_users->stake[id].locktime,"Timelock error");

    
    action(
      permission_level{name(get_self()), name("active")},
      tokencontract,
      "transfer"_n,
      make_tuple(get_self(), username,itr_users->stake[id].token , string("Claim tokens")))
    .send();
}

ACTION addapy( uint64_t locktime, double apy ) {
    require_auth(get_self());

    auto itr_config = _config.find(get_self().value);

    if ( itr_config == _config.end() ) {
        _config.emplace(get_self(), [&](auto& row) {
            row.username = get_self();
            row.APY.push_back({
                .locktime = locktime,
                .apy = apy
            });
        });
    } else {
        _config.modify(itr_config, get_self(), [&](auto& row) {
            row.APY.push_back({
                .locktime = locktime,
                .apy = apy
            });
        });
    }
}

private:

struct stake_tokens
{   
    uint64_t locktime;
    uint64_t starttime;
    asset token;
    double apy;
};

TABLE user {
    name username;
    vector<stake_tokens> stake;

    auto primary_key() const { return username.value; }
  };

typedef multi_index<name("users"), user> users;
users _users;

struct token_apy
{   
    uint64_t locktime;
    double apy;
};


TABLE config { 
    name username;
    vector<token_apy> APY;
    
    auto primary_key() const { return username.value; }
};

typedef multi_index<name("config"), config> configs;
configs _config;

};`,
              open: true,
              choose: true}],
            id: Math.random()*1000000
          },{
            name: 'ricardian',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
          },{
            name: 'include',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
          },
        ]
    },{
        name: 'Marketplace',
        id: 4,
        deployCode: `eosio-cpp -abigen -I include -R ricardian -contract dao -o dao.wasm src/dao.cpp`,    
        files: [{
            name: 'src',
            type: 'folder',
            hide: false,
            files: [{
              name: 'dao.cpp',
              type: 'file',
              files: [] ,
              id: Math.random()*1000000,
              data: ``,
              open: true,
              choose: true}],
            id: Math.random()*1000000
          },{
            name: 'ricardian',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
          },{
            name: 'include',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
          },
        ]
    },{
        name: 'hooktools',
        id: 5,
        deployCode: `eosio-cpp -abigen -I include -R ricardian -contract hooktools -o hooktools.wasm src/hooktools.cpp`,  
        files: [{
            name: 'src',
            type: 'folder',
            hide: false,
            files: [{
              name: 'hooktools.cpp',
              type: 'file',
              files: [] ,
              id: Math.random()*1000000,
              data: `#include <hooktools.hpp>
ACTION hooktools::hi( name nm ) {
/* fill in action body */
print_f("Name : %\\n",nm);
}`,
              open: true,
              choose: true}],
            id: Math.random()*1000000
          },{
            name: 'ricardian',
            type: 'folder',
            files: [],
            hide: false,
            id: Math.random()*1000000
          },{
            name: 'include',
            type: 'folder',
            files: [{
                name: 'hooktools.hpp',
                type: 'file',
                files: [] ,
                id: Math.random()*1000000,
                data: `// Inherit your contract from eosio::contract. 
// This exposes the following data types (available to your smart contract):
// eosio::name receiver - the contract that receives an action (this contract)
// eosio::name code - the contract's blockchain account
// eosio::datastream - the data that's passed to the contract. In this example, it's  your name.
#include <eosio/eosio.hpp>
using namespace eosio;

CONTRACT hooktools : public contract {
public:
  using contract::contract;

  // The ACTION keyword implements the behavior of your contract. 
  // ACTION is a shortcut for [[eosio::action]]  
  ACTION hi( name nm );

  //action_wrapper: first parameter = action to call
  //second parameter = pointer to the action function
  using hi_action = action_wrapper<"hi"_n, &hooktools::hi>;
};`,
                open: true,
                choose: false}],
            hide: false,
            id: Math.random()*1000000
          },
        ]
    }
])
  const [chooseContracts, setChooseContracts] = useState<Files_UP[] | null>(null)
  useEffect(() => {
    const path_ = path.split("/").pop()
    if(path_ && chooseContracts && path_ !== "contracts") {
        fileChandler(chooseContracts?.find((r) => r._name === path_))
    }
    },[path,chooseContracts])
  const [createId, setCreateId] = useState(0)
useEffect(() => {
    try{
        let localContracts:Files_UP[] = JSON.parse(localStorage.getItem('contracts') || '');
        localContracts.reverse()
        if (localContracts) {
            let t:Files_UP[] = contracts.slice(0)
            setChooseContracts(localContracts)
        }
    }catch(e) {
        console.log(e)
    }
},[])
  const changeContract = (id:number) => {
    const __contract = contracts.find(post => post.id === id)
    let _contract = {
      ...__contract,
      id: Math.random()*1000000,
      _name: newContractName
    }
    let localContracts:Files_UP[] = []
    try { localContracts = JSON.parse(localStorage.getItem('contracts') || '') } catch(e) {}
    if (localContracts) {
      if(localContracts.find((data) => data._name === _contract._name)) {
        alert(`Already have contract with "${_contract._name}" name`)
        return
      }
      localContracts.push(_contract as Files_UP)
      localStorage.setItem('contracts', JSON.stringify(localContracts))
    } else {
      localStorage.setItem('contracts', JSON.stringify([_contract]))
    }
    fileChandler(_contract)
    navigate(`contracts/${_contract._name}`)
  }
  const getCode = (data:Files_UP) => {
    let result;
    function getData(data:Files[]) {
        for (let i = 0; i < data.length ; i++){
            if (data[i].data) { 
                result = data[i].data
                return 
            } else {
                getData(data[i].files)
            }
        }
    }
    getData(data.files)
    return result;
  }
  return (
    <div className='codeEditor'>
    <Routes>
        <Route path="/" element={<WelcomePage />} />    
        <Route path="/contracts" element={      <div className='choose-contract'>
        <button onClick={() => navigate('/')} className='back-btn'>back</button>
        <div onClick={() => console.log("newcontract")}>
          <h3>New Project</h3>
          <p style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>Template: 
            <select value={createId} onChange={(e) => setCreateId(Number(e.target.value))} name="" id="">
                {contracts.map((post) => 
                    <option key={post.id} value={post.id}>{post.name}</option>
                )}
            </select>
          </p>
          <p>Project Name:</p>
          <p style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <input value={newContractName} onChange={(e) => setNewContractName(e.target.value)} placeholder='hooktools' style={{color: 'black', fontWeight:600, border: '1px gray solid',width: '100%', padding: '10px', borderRadius:'15px'}}/>
            <button onClick={() => changeContract(createId)} style={{color: '#FFF', border: '1px gray solid',width: '100%', padding: '10px', borderRadius:'15px'}}>Create</button>
          </p>

        </div>
        {chooseContracts?.map((post:any) => 
            <div onClick={() => navigate(`contracts/${post._name}`)}>
                Project - {post._name}
                <p>
                <CodeMirror
                    value={getCode(post)}
                    width='200px'
                    height="100px"
                    extensions={[cpp()]}
                    readOnly={true}
                    
                    theme={vscodeDark}
                />
                </p>
            </div>
        )}
      </div>} />
      </Routes>

      {getChoose() ? 
      
      
      <CodeMirror
            value={getChoose().data}
            width='100%'
            height="100%"
            extensions={[cpp()]}
            // readOnly={true}
            theme={vscodeDark}
            onChange={setChooseData}
          />
          
      :
        <></>
      }
    </div>
  );
}

export default CodeEditor;
